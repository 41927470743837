var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleDialog',{attrs:{"max-width":"950","center_title":"","bts_align":_vm.products_state.add_product_dialog.type === 'info' ? 'center' : 'left'},on:{"close":function($event){_vm.add_product_dialog_model = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [[_vm._v(" "+_vm._s(_vm.products_state.add_product_dialog.title)+" ")]]},proxy:true},{key:"text",fn:function(){return [(_vm.products_state.add_product_dialog.type === 'related')?_c('div',[_c('div',{class:[_vm.$style.notificationText, 'mb-4']},[_vm._v(" Для добавления выбранной номенклатуры в корзину укажите необходимое количество рабочих мест ")]),_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.related_products,"not_resizeble":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}},{key:"item.full_price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.full_price))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('TextField',{class:_vm.$style.quantityInput,attrs:{"value":item.quantity,"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":0,"disabled":item.related_products && item.related_products.length > 0},on:{"input":function($event){return _vm.upd_related_qty(item, $event)}}})]}}],null,true)})],1):_vm._e(),(_vm.products_state.add_product_dialog.type === 'license_key')?_c('div',[(Array.isArray(_vm.product))?_c('div',_vm._l((_vm.product),function(prd,idx){return _c('div',{key:idx,staticClass:"mb-4"},[_c('div',{class:[_vm.$style.notificationText, 'mb-6']},[_vm._v(" "+_vm._s(idx + 1)+". Для добавления в корзину номенклатуры "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(prd.nomenclature))]),_vm._v(" укажите номер лицензионного ключа ")]),_c('v-autocomplete',{class:_vm.classes,attrs:{"menu-props":{
              rounded: 'sm'
            },"items":prd.license_keys,"label":"Выберите лицензионный ключ","hide-details":"","clearable":"","blue_icon":"","outlined":"","background-color":"#fff","required":"","rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ]},on:{"change":function($event){return _vm.license_key_changed($event, prd)},"focus":function($event){return _vm.get_license_keys_for_prd(prd.id)},"blur":_vm.clear_search,"update:search-input":function($event){return _vm.search_keys_for_prd_debounced($event, prd.id)}}})],1)}),0):_c('div',[_c('div',{class:[_vm.$style.notificationText, 'mb-6']},[_vm._v(" Для добавления в корзину номенклатуры "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.product.nomenclature))]),_vm._v(" укажите номер лицензионного ключа ")]),_c('v-autocomplete',{class:_vm.classes,attrs:{"menu-props":{
            rounded: 'sm'
          },"items":_vm.products_state.current_select_keys,"label":"Выберите лицензионный ключ","hide-details":"","clearable":"","blue_icon":"","outlined":"","background-color":"#fff","required":"","rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ]},on:{"focus":function($event){return _vm.get_license_keys(_vm.product.id)},"update:search-input":function($event){return _vm.search_key_debounced($event, _vm.product.id)}},model:{value:(_vm.license_key_model),callback:function ($$v) {_vm.license_key_model=$$v},expression:"license_key_model"}})],1)]):_vm._e(),(_vm.products_state.add_product_dialog.type === 'combined')?_c('div',{staticClass:"mb-6"},[_c('div',[_c('div',{class:[_vm.$style.notificationText, 'mb-4']},[_vm._v(" 1. Для добавления выбранной номенклатуры в корзину укажите необходимое количество рабочих мест ")]),_c('Table',{staticClass:"mb-6",attrs:{"headers":_vm.cols,"items":_vm.related_products,"not_resizeble":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}},{key:"item.full_price",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.full_price))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}},{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_c('TextField',{class:_vm.$style.quantityInput,attrs:{"value":item.quantity,"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":0,"disabled":item.related_products && item.related_products.length > 0},on:{"input":function($event){return _vm.upd_related_qty(item, $event)}}})]}}],null,true)}),(Array.isArray(_vm.product))?_c('div',_vm._l((_vm.product),function(prd,idx){return _c('div',{key:idx,staticClass:"mb-4"},[_c('div',{class:[_vm.$style.notificationText, 'mb-6']},[_vm._v(" 2."+_vm._s(idx + 1)+" Для добавления в корзину номенклатуры "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(prd.nomenclature))]),_vm._v(" укажите номер лицензионного ключа ")]),_c('v-autocomplete',{class:_vm.classes,attrs:{"menu-props":{
                rounded: 'sm'
              },"items":prd.license_keys,"label":"Выберите лицензионный ключ","hide-details":"","clearable":"","blue_icon":"","outlined":"","background-color":"#fff","required":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ]},on:{"change":function($event){return _vm.license_key_changed($event, prd)},"blur":_vm.clear_search,"update:search-input":function($event){return _vm.search_keys_for_prd_debounced($event, prd.id)},"focus":function($event){return _vm.get_license_keys_for_prd(prd.id)}}})],1)}),0):_c('div',[_c('div',{class:[_vm.$style.notificationText, 'mb-6']},[_vm._v(" 2. Для добавления в корзину номенклатуры "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.product.nomenclature))]),_vm._v(" укажите номер лицензионного ключа ")]),_c('v-autocomplete',{class:_vm.classes,attrs:{"menu-props":{
              rounded: 'sm'
            },"items":_vm.product.license_keys,"label":"Выберите лицензионный ключ","hide-details":"","clearable":"","blue_icon":"","outlined":"","background-color":"#fff","required":"","rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ]},on:{"focus":function($event){return _vm.get_license_keys(_vm.product.id)},"update:search-input":function($event){return _vm.search_key_debounced($event, _vm.product.id)}},model:{value:(_vm.license_key_model),callback:function ($$v) {_vm.license_key_model=$$v},expression:"license_key_model"}})],1)],1)]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('div',[_c('v-btn',{attrs:{"color":"accent darken-4","depressed":"","form":"upload_form","disabled":_vm.is_add_disabled},on:{"click":_vm.add_cart_confirm}},[_vm._v(" Добавить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.add_product_dialog_model = false}}},[_vm._v(" Отменить ")])],1)]},proxy:true}]),model:{value:(_vm.add_product_dialog_model),callback:function ($$v) {_vm.add_product_dialog_model=$$v},expression:"add_product_dialog_model"}})}
var staticRenderFns = []

export { render, staticRenderFns }